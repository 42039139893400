import * as React from "react"
import { navigate } from "@reach/router";
import { navigate as navigateGatsby } from "gatsby";
import SEO from "../../../components/seo";
import _ from "lodash";
import SearchTemplate from "../../../templates/search-template";
import $ from "jquery";

class Search extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
            min: "",
            max: "",
            bedrooms: "",
            building: "",
            searchType: "lettings",
            page: 1
        }
    }
    changeStateAndUrl = (searchState) => {
        let urls = [];

        if(typeof searchState.query !== "undefined") {
            if(searchState.query !== "") {
                urls.push(`in-${searchState.query}/`)
                this.setState({ query: searchState.query })
            } else {
                this.setState({ query: searchState.query })
            }
        } else {
            urls.push("in-kensington-and-chelsea")
        }

        // create building URL
        if(typeof searchState.menu !== "undefined") {
            if(searchState.menu.building) {
                urls.push(`type-${searchState.menu.building}`)
                this.setState({ building: searchState.menu.building })
            } else {
                this.setState({ building: ""})
            }
        } else if(this.state.building !== "") {
            urls.push(`type-${this.state.building}`)
        }
        
        // create bedroom URL
        if(searchState.range && searchState.range.bedroom) {
            let bedroom = searchState.range.bedroom.min;
            if(bedroom) {
                urls.push(`${bedroom}-and-more-bedrooms`)
                this.setState({ bedrooms: bedroom })
            } else {
                this.setState({ bedrooms: "" })
            }
        }

        // create Price min and Max URL
        if(typeof searchState.range !== "undefined" && typeof searchState.range.price !== "undefined") {
            let price = searchState.range.price;
            let min = price.min;
            let max = price.max;
            if(min && max) {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if(min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else if(this.state.min !== "") {
                    this.setState({ min: ""})
                } 
                if(max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else if(this.state.max !== ""){
                    this.setState({ max: ""})
                }
            } 
        } else {
            let min = this.state.min;
            let max = this.state.max;
            if(min !== "" && max !== "") {
                urls.push(`between-${min}-and-${max}`)
                this.setState({ min, max })
            } else {
                if(min) {
                    urls.push(`over-${min}`)
                    this.setState({ min })
                } else {
                    this.setState({ min: ""})
                } 
                if(max) {
                    urls.push(`under-${max}`)
                    this.setState({ max })
                } else {
                    this.setState({ max: ""})
                }
            } 
        }

        if(typeof searchState.page !== "undefined") {
            if(searchState.page !== "" && searchState.page != 1) {
                urls.push(`page-${searchState.page}`)
                this.setState({ page: searchState.page })
            }
        }
        let url = urls.join('/');
        let newRoute = '/property/to-rent/'+url;
        if (!newRoute.endsWith('/'))
            newRoute = newRoute + '/';
        navigate(newRoute)
    }
    setData = () => {
        const { params } = this.props;
        const searchParams = params['*'].split('/');
        searchParams.forEach(param => {
            
            if(param.includes("in-")) {
                if(param.includes("in-kensington-and-chelsea")){
                    this.setState({ query: ""})
                } else{
                    this.setState({ query: param.substring(3)})
                }
            }

            if(param.includes("under-")) {
                this.setState({ max: param.substring(6)})
            }
            if(param.includes("over-")) {
                this.setState({ min: param.substring(5)})
            }
            if(param.includes("-and-more-bedrooms")) {
                this.setState({ bedrooms: param.split("-")[0]})
            }
            if(param.includes("type-")) {
                this.setState({ building: param.split("-")[1]})
            }
            if(param.includes("between-")) {
                this.setState({ 
                    min: param.split("-")[1],
                    max: param.split("-")[3]
                })
            }
        });
    }
    componentDidMount() {

        // $(window).scroll(function () {
        //     //set scroll position in session storage
        //     sessionStorage.scrollPos = $(window).scrollTop();
        //   });
        //   var init = function () {
        //      //return scroll position in session storage
        //      $(window).scrollTop(sessionStorage.scrollPos || 0)
        //   };
        //   window.onload = init;
        if(sessionStorage.getItem('searchindex') != null){
        
            var idelement = "propertyList_"+sessionStorage.getItem('searchindex');
            var positionSet = false;
            setTimeout(function() { 
                if($(`#${idelement}`).offset()){
                    positionSet = true
                    $('html, body').animate({
                        scrollTop: $(`#${idelement}`).offset().top - 150
                    }, 100);  
                } 
            }, 500)
            setTimeout(function() { 
                if($(`#${idelement}`).offset() && !positionSet){
                    $('html, body').animate({
                        scrollTop: $(`#${idelement}`).offset().top - 150
                    }, 100);  
                } 
                sessionStorage.removeItem('searchindex')
            }, 1000)
            
        } else {
             // load on top
                $(document).ready(function(){
                    $("html, body").scrollTop(0)
                });
                // load on top
        }

        // if(window) {
        //     window.scroll({
        //         top: 0,
        //         behavior: 'smooth'
        //     });
        // }
        this.setData();
    }
    changeToMapUrl = () => {
        let pathname = this.props.location.pathname.replace("/property/", "/property-map/");
        let url = ""
        if(pathname.includes("page-")) {
            let urls = pathname.split("/");
            urls.pop();
            url = urls.join("/");
        } else {
            url = this.props.location.pathname.replace("/property/", "/property-map/");
        }
        if (!url.endsWith('/'))
            url = url + '/';
        navigateGatsby(url)
    }
    changeToListUrl = () => {
        let url = this.props.location.pathname.replace("/property-map/", "/property/");
        if (!url.endsWith('/'))
            url = url + '/';
        navigateGatsby(url)
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.setData();
        }
    }
    render() {
        var search_area = "";
        var price_range = "", property_type = "", bedrooms = "";
        if(this.props.params["*"]) {
            search_area = this.props.params["*"].split("/").filter(c => c.includes("in")).length > 0 ? "in "+ this.props.params["*"].split("/").filter(c => c.includes("in"))[0].split("in-")[1].split("-").map(c => c !== "and" ? _.upperFirst(c) : _.lowerCase(c)).join(" ") : "in Kensington and Chelsea";

            price_range = this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("under-") || c.includes("between-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("over-") || c.includes("under-") || c.includes("between-"))[0].split("-").map(c => isNaN(c) ? c : `£${parseInt(c).toLocaleString()}`).join(" ") : "";

            // property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => _.upperFirst(c))[1]+"s" : "";

            property_type = this.props.params["*"].split("/").filter(c => c.includes("type-")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("type-"))[0].split("type-").map(c => c)[1]+"s" : "";

            var property_type_uppercase = _.upperFirst(property_type);

            bedrooms = this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms")).length > 0 ? this.props.params["*"].split("/").filter(c => c.includes("-and-more-bedrooms"))[0].split("-and-more-bedrooms")[0] : "";
        }
        return (
            <>
                <SEO 
                    title={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `${property_type_uppercase} to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range}` :
                        (property_type !== "" && bedrooms !== "") ?
                        `${property_type_uppercase} to rent ${search_area} with more than ${bedrooms} bedrooms` :
                        (price_range !== "" && bedrooms !== "") ?
                        `property to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range}` :
                        (price_range !== "" && property_type !== "") ?
                        `${property_type_uppercase} to rent ${search_area} ${price_range}` :
                        (bedrooms !== "") ?
                        `property to rent ${search_area} with more than ${bedrooms} bedrooms` :
                        (property_type !== "") ?
                        `${property_type_uppercase} to rent ${search_area}` :
                        (price_range !== "") ?
                        `property to rent ${search_area} ${price_range}` :
                        `property to rent ${search_area}`
                    } 
                    description={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `Find ${property_type} to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range} with tlc estate agents. Contact one of our property professionals today to get assistance in finding the right property for you ${search_area}.`:
                        (property_type !== "" && bedrooms !== "") ?
                        `Find the latest ${property_type} to rent ${search_area} with more than ${bedrooms} bedrooms. Contact tlc estate agents ${search_area}, to arrange a viewing.` :
                        (price_range !== "" && bedrooms !== "") ?
                        `Explore the list of property to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range} with tlc estate agents and request a viewing for the property that interests you.`:
                        (price_range !== "" && property_type !== "") ?
                        `Check the latest collection of ${property_type} to rent ${search_area} ${price_range} with tlc estate agents ${search_area}.`:
                        (bedrooms !== "") ?
                        `Find property to rent ${search_area} with more than ${bedrooms} bedrooms here. Contact one of our property professionals today to get assistance in finding the right property for you ${search_area}.`:
                        (property_type !== "") ?
                        `Searching for ${property_type} to rent ${search_area}? Take a look at the latest properties on the market and give us a call to arrange a viewing today.`:
                        (price_range !== "") ?
                        `Locate the best property to rent ${search_area} ${price_range} with tlc estate agents. Give us a call to arrange a viewing today.`:
                        `Explore our stunning range of property to rent ${search_area}. Contact our property professionals to find the right property to rent ${search_area}..`
                    } 
                    location={this.props.location}
                />
                <SearchTemplate
                    {...this.state}
                    h1={
                        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
                        `${property_type} to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range}` :
                        (property_type !== "" && bedrooms !== "") ?
                        `${property_type} to rent ${search_area} with more than ${bedrooms} bedrooms` :
                        (price_range !== "" && bedrooms !== "") ?
                        `property to rent ${search_area} with more than ${bedrooms} bedrooms ${price_range}` :
                        (price_range !== "" && property_type !== "") ?
                        `${property_type} to rent ${search_area} ${price_range}  ` :
                        (bedrooms !== "") ?
                        `property to rent ${search_area} with more than ${bedrooms} bedrooms` :
                        (property_type !== "") ?
                        `${property_type} to rent ${search_area} ` :
                        (price_range !== "") ?
                        `property to rent ${search_area} ${price_range}` :
                        `property to rent ${search_area}`
                    }
                    changeStateAndUrl={this.changeStateAndUrl}
                    changeToMapUrl={this.changeToMapUrl}
                    changeToListUrl={this.changeToListUrl}
                    location={this.props.location}
                    mapView={this.props.location.pathname.includes("/property-map")}
                    search_area={search_area}
                />
            </>
        )
    }
}

export default Search;